<template>
  <div>
    <floating-menu :event-id="eventId"></floating-menu>
    <b-card v-if="!(eventData && eventCategories && eventTypes && companies && fieldGroups)">
      <b-row>
        <b-col cols="12">
          <div class="text-center">
            <b-spinner label="Tunggu..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <event-edit-content :event-data="eventData" :field-groups="fieldGroups" :event-categories="eventCategories" :event-types="eventTypes" :companies="companies" v-if="eventData && eventCategories && eventTypes && companies && fieldGroups" />
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BSpinner } from 'bootstrap-vue'
import EventEditContent from './EventEditContent.vue'
import router from '@/router'
import moment from 'moment'
import FloatingMenu from './FloatingMenu.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    FloatingMenu,
    EventEditContent
  },

  data() {
    return {
      eventId: router.currentRoute.params.id,
      eventData: null,
      eventCategories: null,
      eventTypes: null,
      fieldGroups: null,
      questions: null
    }
  },

  methods: {
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    fetchQuestions() {
      this.$http.get('/admin/v1/questions')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.questions = []

            currentData.forEach((item, index) => {
              this.questions.push({
                label: item.name,
                value: item.id
              })
            }) 
          }
       })
    },
    fetchFieldGroups() {
      this.$http.get('/admin/v1/field_groups')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.fieldGroups = []

            currentData.forEach((item, index) => {
              this.fieldGroups.push({
                name: item.name,
                fields: item.fields
              })
            }) 
          }
       })
    },
    fetchEventCategories() {
      this.$http.get('/admin/v1/event_categories')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventCategories = []

            currentData.forEach((item, index) => {
              this.eventCategories.push({
                label: item.category,
                value: item.id
              })
            })
            
          }
      })
    },
    fetchCompanies() {
      this.$http.get('/admin/v1/companies')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.companies = []

            currentData.forEach((item, index) => {
              this.companies.push({
                label: item.name,
                value: item.id
              })
            })
            
          }
      })
    },
    fetchEventTypes() {
      this.$http.get('/admin/v1/event_types')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventTypes = []

            currentData.forEach((item, index) => {
              this.eventTypes.push({
                label: item.type,
                value: item.id
              })
            })
            
          }
      })
    },

    fetchEventData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventData = {
              id: currentData.id,
              name: currentData.name,
              banner_url: currentData.banner_url,
              nametag_url: currentData.nametag_url,
              logo_url: currentData.logo_url,
              file_url: currentData.file_url,
              banner_blob: null,
              nametag_blob: null,
              logo_blob: null,
              file_blob: null,
              date_start: currentData.date_start,
              date_end: currentData.date_end,
              start_registration_date: currentData.start_registration_date,
              end_registration_date: currentData.end_registration_date,
              description: currentData.description,
              location: currentData.location,
              event_type_id: currentData.event_type_id,
              event_category_id: currentData.event_category_id,
              facebook_link: currentData.facebook_link,
              instagram_link: currentData.instagram_link,
              linkedin_link: currentData.linkedin_link,
              web_link: currentData.web_link,
              success_register_message: currentData.success_register_message,
              price: currentData.price,
              company_id: currentData.company_id,
              itineraries: currentData.itineraries,
              sliders: currentData.sliders,
              sliders_blob: null,
              date: null,
              registration_date: null,
              fields: currentData.fields,
              use_payment_gateway: (currentData.use_payment_gateway == 1),
              
              info_under_submit: currentData.info_under_submit,
              use_bold_on_info: currentData.use_bold_on_info == 1?true: false,
              close_registration: currentData.close_registration == 1?true: false,
              date_display: currentData.date_display,
              payment_info: currentData.payment_info,

              font_style: currentData.font_style,
              font_color: currentData.font_color,
              font_size: currentData.font_size,
              company_first: currentData.company_first,
              font_blob: null,
              font_url: currentData.font_url,

              question_id: currentData.question_id
            }
          }
      })
    }
  },
  created() {
    this.fetchEventData()
    this.fetchCompanies()
    this.fetchEventCategories()
    this.fetchEventTypes()
    this.fetchFieldGroups()
    // this.fetchQuestions()
  },
}
</script>